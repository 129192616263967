import React, { useEffect, useState } from 'react';
import { Button, Input, Spin } from 'antd';

import { openNotification } from '../../../meta/globalToaster';
import { AddPartAlert } from '../../../api/partAlertAPI';

const AddPartAlertForm = ({ setOpenCreatePartAlertModal, updatedValues }: any) => {
  const [loading, setLoading] = useState(false);
  const [successToggle, setSuccessToggle] = useState(true);

  const [partAlertName, setPartAlertName] = useState('');

  const successSubmission = () => {
    setLoading(false);
    setSuccessToggle(!successToggle);
    setPartAlertName('');

    setOpenCreatePartAlertModal(false);
    updatedValues();
  };

  const failSubmission = (message: any) => {
    setLoading(false);
    openNotification({ message });
  };

  const addPartAlertButtonClick = () => {
    if (partAlertName !== null && partAlertName !== '') {
      setLoading(true);
      AddPartAlert({
        name: partAlertName,
      }).then((data) => {
        try {
          if (data['result']) {
            if (data['result'] === 'SUCCESS') {
              successSubmission();
            } else if (data['result'] === 'FAILED') {
              failSubmission(data['data']['errorMessage']);
            } else {
              failSubmission('Please try again later!');
            }
          } else {
            failSubmission('Please try again later!');
          }
        } catch (error) {
          failSubmission('Please try again later!');
        }
      });
    } else {
      failSubmission('PartAlert Required.');
    }
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12 mt-3 mb-3">
              <p className="a-input-label">Name</p>
              <Input
                value={partAlertName}
                className="a-input-box"
                placeholder="PartAlert name..."
                onChange={(e) => {
                  setPartAlertName(e.target.value);
                }}
                disabled={loading}
              />
            </div>
          </div>

          <div className="row d-flex justify-content-end">
            <div className="col-sm-3 mt-3">
              <Button
                type="primary"
                size="large"
                style={{ width: '100%' }}
                // loading={loading}
                onClick={() => {
                  addPartAlertButtonClick();
                }}
                loading={loading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPartAlertForm;
