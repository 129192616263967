import React, { useEffect, useState } from 'react';

const ProductStatusSwitchComponent = ({
  item,
  onChangePartProductStatusItem,
  defaultProductStatusList,
  selectedPart,
}: any) => {
  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    if (item?.id) {
      if (defaultProductStatusList[item?.id]) {
        let tmp = defaultProductStatusList[item?.id];

        setStatus(tmp == 'ON' ? true : false);
      } else {
        setStatus(false);
      }
    }
  }, [selectedPart]);

  const onClickDiv = () => {
    onChangePartProductStatusItem(item?.id, status ? 'OFF' : 'ON');

    setStatus(!status);
  };

  return (
    <div className="row mt-2">
      <div className="col-sm-3">
        <div
          style={{ backgroundColor: status ? '#4aaf56' : '#cccccc' }}
          className="switch-btn"
          onClick={() => {
            onClickDiv();
          }}
        ></div>
      </div>
      <div className="col-sm-9">
        <p className="s1-part-sub-text">{item?.name}</p>
      </div>
    </div>
  );
};

export default ProductStatusSwitchComponent;
