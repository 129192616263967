import React from 'react';
import PartAlertSwitchComponent from './PartAlertSwitchComponent';

const PartAlertComponent = ({
  partAlertList,
  onChangePartAlertStatusItem,
  defaultAlertStatusList,
  selectedPart,
}: any) => {
  return (
    <div>
      {partAlertList?.map((item: any) => {
        return (
          <PartAlertSwitchComponent
            key={item.id}
            item={item}
            onChangePartAlertStatusItem={onChangePartAlertStatusItem}
            defaultAlertStatusList={defaultAlertStatusList}
            selectedPart={selectedPart}
          />
        );
      })}
    </div>
  );
};

export default PartAlertComponent;
