export default function renderLocationStatus(role: any) {
  switch (role) {
    case 1:
      return (
        <h3>
          <span className="ui green label">Active</span>
        </h3>
      );

    case 0:
      return (
        <h3>
          <span className="ui red label">Inactive</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}

export function renderActiveDeleteStatus(role: any) {
  switch (role) {
    case 1:
      return (
        <h3>
          <span className="ui green label">Active</span>
        </h3>
      );

    case 0:
      return (
        <h3>
          <span className="ui red label">Inactive</span>
        </h3>
      );
    default:
      return (
        <h3>
          <span className="ui teal label">Not Mention</span>
        </h3>
      );
  }
}
