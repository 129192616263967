import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FileImageOutlined, SettingOutlined, LoginOutlined } from '@ant-design/icons';
import { CgProfile } from 'react-icons/cg';
import { Layout, Menu, Button, Image, Avatar, Space, Dropdown, Typography } from 'antd';

const { Sider, Content } = Layout;
const { Text } = Typography;

export const ProtectedLayout = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState('/');
  const [menuItems, setMenuItems] = useState<any>([]);
  const [name, setName] = useState<any>('');

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const pathName = location.pathname;

    if (pathName?.includes('job')) {
      setSelectedKeys('/job');
    } else {
      setSelectedKeys(pathName);
    }
  }, [location.pathname]);

  useEffect(() => {
    const role = localStorage.getItem('h-kck-auth-role');
    setName(`${localStorage.getItem('h-kck-auth-firstName')} ${localStorage.getItem('h-kck-auth-lastName')}`);

    if (role === 'ADMIN') {
      setMenuItems([
        {
          key: '/user',
          icon: <FileImageOutlined />,
          label: 'User',
        },
        {
          key: '/job',
          icon: <FileImageOutlined />,
          label: 'Job',
        },
        {
          key: '/location',
          icon: <FileImageOutlined />,
          label: 'Location',
        },
        {
          key: '/product-status',
          icon: <FileImageOutlined />,
          label: 'Product Status',
        },
        {
          key: '/part-alert',
          icon: <FileImageOutlined />,
          label: 'Part Alert',
        },
      ]);
    } else {
      setMenuItems([
        {
          key: '/job',
          icon: <FileImageOutlined />,
          label: 'Job',
        },
      ]);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('h-kck-auth-id');
    localStorage.removeItem('h-kck-auth-firstName');
    localStorage.removeItem('h-kck-auth-lastName');
    localStorage.removeItem('h-kck-auth-email');
    localStorage.removeItem('h-kck-auth-role');
    localStorage.removeItem('h-kck-auth-token');
    localStorage.removeItem('h-kck-is-valid-auth');

    window.location.href = '/login';
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} className="sidebar-left" width={288}>
        <div className="demo-logo-vertical" style={{ textAlign: 'center' }}>
          {collapsed ? (
            <Image width={30} src="/images/fav.png" style={{ padding: '15px 0' }} preview={false} />
          ) : (
            <Image
              width={256}
              src="/images/logo_white.png"
              style={{ padding: '20px 0', marginTop: '10px' }}
              preview={false}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKeys]}
          items={menuItems}
          onClick={(item) => {
            navigate(item.key);
          }}
        />

        <Dropdown
          className="user-dropdown"
          menu={{
            items: [
              {
                key: '2',
                label: 'Profile',
                icon: <CgProfile />,
                onClick: () => {
                  navigate(`/user/${localStorage.getItem('h-kck-is-user-id')}`);
                },
              },
              {
                key: '3',
                label: 'Settings',
                icon: <SettingOutlined />,
                onClick: () => {
                  navigate(`/setting`);
                },
              },
              {
                type: 'divider',
              },
              {
                key: '4',
                label: 'Log out',
                onClick: () => {
                  handleLogout();
                },
                icon: <LoginOutlined />,
              },
            ],
            // onClick: handleMenuClick,
          }}
          trigger={['click']}
        >
          <Button
            className="user-button"
            icon={
              <Avatar className="p-avatar" shape="square">
                {name?.slice(0, 2).toUpperCase()}
              </Avatar>
            }
          >
            {name}
          </Button>
        </Dropdown>
      </Sider>
      <Layout style={{ height: '100vh', overflow: 'scroll', background: '#F2F4F7' }}>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};
