import React from 'react';
import ProductStatusSwitchComponent from './ProductStatusSwitchComponent';

const ProductStatusComponent = ({
  productStatusList,
  onChangePartProductStatusItem,
  defaultProductStatusList,
  selectedPart,
}: any) => {
  return (
    <div>
      {productStatusList?.map((item: any) => {
        return (
          <ProductStatusSwitchComponent
            key={item.id}
            item={item}
            onChangePartProductStatusItem={onChangePartProductStatusItem}
            defaultProductStatusList={defaultProductStatusList}
            selectedPart={selectedPart}
          />
        );
      })}
    </div>
  );
};

export default ProductStatusComponent;
