import React, { useEffect, useState } from 'react';

let colorList = ['#cccccc', '#f3ab2c', '#4aaf56', '#ff0000'];
let statusList = ['OFF', 'ORANGE', 'GREEN', 'RED'];

const PartAlertSwitchComponent = ({ item, onChangePartAlertStatusItem, defaultAlertStatusList, selectedPart }: any) => {
  const [status, setStatus] = useState<number>(0);

  useEffect(() => {
    if (item?.id) {
      if (defaultAlertStatusList[item?.id]) {
        let tmp = defaultAlertStatusList[item?.id];

        if (tmp == 'OFF') {
          setStatus(0);
        } else if (tmp == 'ORANGE') {
          setStatus(1);
        } else if (tmp == 'GREEN') {
          setStatus(2);
        } else if (tmp == 'RED') {
          setStatus(3);
        }
      } else {
        setStatus(0);
      }
    }
  }, [selectedPart]);

  const onClickDiv = () => {
    let tempStatus = (status + 1) % 4;

    setStatus(tempStatus);
    onChangePartAlertStatusItem(item?.id, statusList[tempStatus]);
  };

  return (
    <div className="row mt-2">
      <div className="col-sm-3">
        <div
          style={{ backgroundColor: colorList[status] }}
          className="switch-btn"
          onClick={() => {
            onClickDiv();
          }}
        ></div>
      </div>
      <div className="col-sm-9">
        <p className="s1-part-sub-text">{item?.name}</p>
      </div>
    </div>
  );
};
export default PartAlertSwitchComponent;
