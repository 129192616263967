import React from 'react';

import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button, Modal } from 'antd';

import { FaEdit } from 'react-icons/fa';
import { AiOutlineUserAdd } from 'react-icons/ai';

import renderPartAlertStatus from '../../meta/renderOther';
import AddPartAlertForm from './forms/AddPartAlertForm';
import EditPartAlertForm from './forms/EditPartAlertForm';
import { readAll } from '../../api/partAlertAPI';

const PartAlertListComponent = () => {
  const [partAlertItems, setPartAlertItems] = useState([]);
  const [pending, setPending] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreatePartAlertModal, setOpenCreatePartAlertModal] = useState(false);
  const [clickedPartAlert, setClickedPartAlert] = useState<any>();

  const [togglePartAlerts, setTogglePartAlerts] = useState(false);

  const partAlertEditClick = (value: any) => {
    setOpenEditModal(true);
    setClickedPartAlert(value);
  };

  const updatedValues = () => {
    setTogglePartAlerts(!togglePartAlerts);
  };

  useEffect(() => {
    readAll().then((data) => {
      try {
        if (data['result']) {
          if (data['result'] === 'SUCCESS') {
            setPartAlertItems(data['data']);
            setPending(false);
          } else if (data['result'] === 'FAILED') {
            setPending(true);
          } else {
            setPending(true);
          }
        } else {
          setPending(true);
        }
      } catch (error) {
        setPending(true);
      }
    });
  }, [partAlertItems.length, togglePartAlerts]);

  const columns: any = useMemo(
    () => [
      {
        name: 'Name',
        selector: (row: any) => row.name,
        sortable: true,
      },
      {
        name: '',
        selector: (row: any) => renderPartAlertStatus(row.isActive),
      },
      {
        name: '',
        selector: (row: any) => {
          return (
            <button className="btn-link-style" onClick={() => partAlertEditClick(row)}>
              <FaEdit />
            </button>
          );
        },
      },
    ],
    []
  );

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      style: {
        fontSize: '16px',
      },
    },
  };

  return (
    <div className="container">
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-sm-2">
          <Button
            type="primary"
            size="large"
            style={{ width: '100%' }}
            onClick={() => {
              setOpenCreatePartAlertModal(true);
            }}
            loading={pending}
          >
            <AiOutlineUserAdd /> Add Part Alert
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table_outer_div">
            <DataTable
              title="PartAlerts"
              data={partAlertItems}
              columns={columns}
              fixedHeader
              fixedHeaderScrollHeight="100%"
              pagination
              progressPending={pending}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>

      <Modal
        title="Edit PartAlert"
        centered
        open={openEditModal}
        onCancel={() => setOpenEditModal(!openEditModal)}
        footer={null}
      >
        <EditPartAlertForm
          clickedPartAlert={clickedPartAlert}
          setOpenEditModal={setOpenEditModal}
          updatedValues={updatedValues}
        />
      </Modal>

      <Modal
        // width={'80vw'}
        title="Add Part Alert"
        centered
        open={openCreatePartAlertModal}
        onCancel={() => setOpenCreatePartAlertModal(false)}
        footer={null}
      >
        <AddPartAlertForm setOpenCreatePartAlertModal={setOpenCreatePartAlertModal} updatedValues={updatedValues} />
      </Modal>
    </div>
  );
};

export default PartAlertListComponent;
