import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FormGroup } from 'reactstrap';

import { Button } from 'antd';
import { openNotification } from '../../../meta/globalToaster';
import { editLocation } from '../../../api/locationAPI';

const EditLocationForm = ({ clickedLocation, setOpenEditModal, updatedValues }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  function successSubmission() {
    setOpenEditModal(false);
    setIsLoading(false);
    updatedValues();
  }

  function failSubmission(head: any, sub: any) {
    setIsLoading(false);
    openNotification({ message: sub });
  }

  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12">
          <div className="invoice-form-div">
            <Formik
              enableReinitialize={true}
              initialValues={{
                id: clickedLocation && clickedLocation.id,
                name: clickedLocation && clickedLocation.name,
                isActive: clickedLocation && clickedLocation.isActive,
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Required'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setIsLoading(true);

                editLocation({
                  id: values.id,
                  name: values.name,
                  isActive: values.isActive,
                }).then((data: any) => {
                  try {
                    if (data['result']) {
                      if (data['result'] === 'SUCCESS') {
                        successSubmission();
                      } else if (data['result'] === 'FAILED') {
                        failSubmission('¡Error!', data['data']['errorMessage']);
                      } else {
                        failSubmission('Error!', 'Please try again later!');
                      }
                    } else {
                      failSubmission('Error!', 'Please try again later!');
                    }
                  } catch (error) {
                    failSubmission('Error!', 'Please try again later!');
                  }
                });
              }}
            >
              <Form>
                <div className="row d-flex justify-content-center">
                  <div className="col-sm-8 mb-4">
                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Location Name"
                            disabled={isLoading}
                          />
                          <ErrorMessage name="name">
                            {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 mb-3">
                        <FormGroup className="input-payment-box">
                          <Field
                            name="isActive"
                            as="select"
                            className="form-control"
                            placeholder="Select Playing State"
                            disabled={isLoading}
                          >
                            <option value="1">Activate</option>
                            <option value="0">InActive</option>
                          </Field>
                          <ErrorMessage name="isActive">
                            {(msg: any) => <div style={{ color: 'red' }}>{msg}</div>}
                          </ErrorMessage>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 text-center">
                          <Button
                            type="primary"
                            size="large"
                            style={{ width: '100%' }}
                            htmlType="submit"
                            loading={isLoading}
                          >
                            Update Location
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocationForm;
