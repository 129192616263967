import axios from 'axios';

export const readAll = async () => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/location/read_all.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const AddLocation = async ({ name }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('name', name);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/location/create.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const editLocation = async ({ id, name, isActive }: any) => {
  try {
    const token: any = localStorage.getItem('h-kck-auth-token');
    let formData: any = new FormData();

    formData.append('id', id);
    formData.append('name', name);
    formData.append('isActive', isActive);
    formData.append('token', token);

    const { data } = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/api/location/edit.php`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    });

    return data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
